import React, { useState } from 'react'; // Importa useState
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Slider } from '../../components/Slider/Slider.jsx';
import { useNavigate } from 'react-router-dom';
import LogoCarousel from '../../components/Carousel/Carousel.jsx';
import './Home.css';
import logoDiarioSolo from '../../components/Assets/NuevoLogoDiarioModerno.webp';
import logoRadioSolo from '../../components/Assets/NuevoLogoRadioModerno1.webp';
import logoImpresionesSolo from '../../components/Assets/NuevoLogoDiseñoModerno1.webp';
import logoConcursoSolo from '../../components/Assets/NuevoLogoConcursoModerno2.webp';
import logoPueblos from '../../components/Assets/logoPueblos800.webp';
import bannerAbril from '../../components/Assets/bannerAbril.jpeg';

const Home = () => {
  const navigate = useNavigate();
  const [showAd, setShowAd] = useState(true); // Estado para controlar la visibilidad de la publicidad

  const handleDiarioClick = () => {
    navigate('/Diario');
  };

  const handleRadioClick = () => {
    navigate('/Radio');
  };

  const handleEdicionClick = () => {
    navigate('/Edicion');
  };

  const handleConcursoClick = () => {
    navigate('/Concurso');
  };

  const handlePueblosClick = () => {
    navigate('/Pueblos');
  };

  const closeAd = () => {
    setShowAd(false); // Oculta la publicidad
  };

  return (
    <div className="home-container">
      {showAd && (
        <div className="ad-container">
          <button className="close-ad-button" onClick={closeAd}>X</button>
          <img src={bannerAbril} alt="Publicidad" className="ad-image" />
        </div>
      )}
      <Slider />
      <Container className="cards-container">
        <Row>
          <Col md={6} className="mb-4">
            <Card border="dark" onClick={handleDiarioClick} className="navigate-card">
              <Card.Img variant="top" src={logoDiarioSolo} alt="Diario" className="navigate-image" />
              <Card.Body>
                <Card.Title className="card-title">Periódico Dígital</Card.Title>
                  <Card.Text className="text-center">
                    Versión digital del periódico gráfico
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-4">
            <Card border="dark" onClick={handleRadioClick} className="navigate-card">
              <Card.Img variant="top" src={logoRadioSolo} alt="Radio" className="navigate-image" />
              <Card.Body>
                <Card.Title className="card-title">Radio</Card.Title>
                  <Card.Text className="text-center">
                    Programa periodistico
                    radial semanal
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-4">
            <Card border="dark" onClick={handleEdicionClick} className="navigate-card">
              <Card.Img variant="top" src={logoImpresionesSolo} alt="Diseño" className="navigate-image" />
              <Card.Body>
                <Card.Title className="card-title">Diseño</Card.Title>
                  <Card.Text className="text-center">
                    Diseño, diagramación e impresión de revistas, periódicos y libros
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-4">
            <Card border="dark" onClick={handleConcursoClick} className="navigate-card">
              <Card.Img variant="top" src={logoConcursoSolo} alt="Concurso" className="navigate-image" />
              <Card.Body>
                <Card.Title className="card-title">Concurso</Card.Title>
                  <Card.Text className="text-center">
                    ¡Concurso Nacional de Poesía, Cuento Breve, Dibujo y Fotografía para Adultos, Adolescentes y Niños!
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12} className="mb-4">
            <Card border="dark" onClick={handlePueblosClick} className="navigate-card">
            <Card.Img variant="top" src={logoPueblos} alt="Pueblos" className="navigate-image2" />
              <Card.Body>
                <Card.Title className="card-title">Voces de los Pueblos</Card.Title>
                  <Card.Text className="text-center">
                    Descubre las historias y voces de nuestros pueblos
                  </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <LogoCarousel />
    </div>
  );
};

export default Home;