import { createSelector } from 'reselect';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import ReactAudioPlayer from 'react-audio-player';
import { getPrograms } from "../../Redux/Actions/Index";
import moment from 'moment';
import PaginationComponent from '../../components/Pagination/Pagination';
import "./Radio.css";

// Crear un selector memorizado
const selectPrograms = createSelector(
  [(state) => state.allPrograms],
  (allPrograms) => allPrograms.map(program => ({
    ...program,
    formattedDate: program.date ? moment(program.date, 'DD-MM-YYYY').format('DD/MM/YYYY') : "Fecha no disponible"
  }))
);

const Radio = () => {
  const dispatch = useDispatch();
  const programs = useSelector(selectPrograms);

  const [currentPage, setCurrentPage] = useState(1);
  const programsPerPage = 10; // Número de programas por página
  const totalPages = Math.ceil(programs.length / programsPerPage);

  useEffect(() => {
    dispatch(getPrograms());
  }, [dispatch]);

// Convierte y ordena las fechas antes de la paginación
const sortedPrograms = programs.slice().sort((a, b) => {
const dateA = moment(a.date, 'DD/MM/YYYY');
const dateB = moment(b.date, 'DD/MM/YYYY');
return dateB - dateA; // Ordena de más reciente a más antigua
});

  const indexOfLastProgram = currentPage * programsPerPage;
  const indexOfFirstProgram = indexOfLastProgram - programsPerPage;
  const currentPrograms = sortedPrograms.slice(indexOfFirstProgram, indexOfLastProgram);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container className="radio-container">
      <Row className="audio-list-section">
        <Col md={12}>
          <h2>Programas subidos</h2>
          {currentPrograms && currentPrograms.length > 0 ? (
            currentPrograms.map((program, index) => {
              // Usa moment.js para formatear la fecha
              const formattedDate = program.date
                ? moment(program.date, 'DD-MM-YYYY').format('DD/MM/YYYY')
                : "Fecha no disponible";

                return (
                  <Card key={index} className="audio-card mb-4">
                    <Card.Body>
                    <div className="program-title" data-testid="program-title">
                      {program.title || "Sin título"}
                    </div>
                      <ReactAudioPlayer
                        src={program.audioUrl}
                        controls
                        style={{ width: '100%' }}
                      />
                      <Card.Text>{program.description}</Card.Text>
                      <Card.Footer className="text-muted"><small>{formattedDate}</small></Card.Footer>
                    </Card.Body>
                  </Card>
                )
              })
            ) : (
            <p>No hay programas subidos.</p>
          )}
        </Col>
      </Row>
      <PaginationComponent 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={handlePageChange} 
      />
    </Container>
  );
};

export default Radio;
